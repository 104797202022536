import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import { Typography, useMediaQuery } from "@mui/material";
import ConsCard from '../Components/ConsCard';
import axios from 'axios';
import LoadingPage from '../Components/LoadingPage';

export default function Consultation() {
    const matches = useMediaQuery("(min-width:700px)");
    const [lang, setLang] = useState(localStorage.getItem("lang"));
    const { t } = useTranslation();
    const [open, setOpen] = useState(true);
    const [im,setIm] = useState()
    const [cons,setCons] = useState([])
    const windowHeight = React.useRef(window.innerHeight);

    useEffect(()=>{
        window.scrollTo(0,0)
        axios.get(`${process.env.REACT_APP_API_URL}consultations`, {
          headers: {
            "Accept-Language": lang,
          },
        }).then(res=>{
          setIm(res.data.data.header_image)
          setCons(res.data.data.consultations)
          setOpen(false)
        })
    },[])
  return (
    <div>
      {open && <LoadingPage open={open}/>}

      {!open && <>
       {/* <div
        style={{
          position: "relative",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          height: matches ? windowHeight.current  : "300px",
        }}
      >
        <img
          src={im}
          style={{
            height: matches ? windowHeight.current  : "300px",
            objectFit: "cover",
            width: "100%",
          }}
        />
        <div className="Slider-overLay"></div>
        <p
          className="sections-text"
          style={{ letterSpacing: lang === "en" ? "" : "0px" }}
        >
          {t("CONSULTATION")}
        </p>
      </div> */}
    {/* <Typography sx={{fontWeight:'bold',color:'#3D3B37',fontSize:'20px',mt:'10px',letterSpacing:lang==='en'?'':'0px'}}>
    {t("CONSULTATION")}
    </Typography> */}

        <div style={{marginTop:'120px'}}>
          <div className='cons_container'>
            {cons.map((c)=>
            <ConsCard image={c.main_image} title={c.title} id={c.id}/>
            )}
          </div>
          </div>
          </>}
    </div>
  )
}
