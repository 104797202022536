import React, { useEffect, useState } from 'react'
import { Box, useMediaQuery, Pagination } from '@mui/material'
import GalleryCard from '../Components/GalleryCard';
import axios from 'axios';
import LoadingPage from '../Components/LoadingPage';
import { useTranslation } from "react-i18next";

export default function Gallery() {
    const [array, setArray] = useState([]);
    const [im,setIm] = useState()
    const [open, setOpen] = useState(true);
    const [title,setTitle] = useState()
    const { t } = useTranslation();
    const matches = useMediaQuery('(min-width:700px)');
    useEffect(()=>{
      window.scrollTo(0,0)
      axios.get(`${process.env.REACT_APP_API_URL}gallery`, {
        headers: {
          "Accept-Language": lang,
        },
      }).then(res =>{
       setIm(res.data.data.header_image)
       setTitle(res.data.data.title)
       setArray(res.data.data.gallery)
       setOpen(false)
      })
  },[])
    const [page, setPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(matches?9:10);

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const [lang,setLang] = useState(localStorage.getItem('lang'))

    const indexOfLastItem = page * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = array.slice(indexOfFirstItem, indexOfLastItem);
    const windowHeight = React.useRef(window.innerHeight);

    return (
        <div>
          {open && <LoadingPage open={open}/>}
          {!open && <>
             {/* <div style={{ position: "relative", display: "flex",flexWrap:'wrap',
      justifyContent: "center", alignItems: "center", height: matches ? windowHeight.current : "300px" }}>
  <img
    src={im}
    style={{
      height: matches ?  windowHeight.current : "300px",
      objectFit: "cover",
      width: "100%",
    }}
  />
  <div className="Slider-overLay"></div>
  <p
    className="sections-text"
    style={{ letterSpacing: lang === "en" ? "" : "0px"}}
  >
                        {t("GALLERY")}
  </p>
</div> */}
<div style={{marginTop:'150px'}}>

            <GalleryCard data={currentItems} />

      
            <Box display="flex" justifyContent="center" mt={'50px'} mb={'50px'} >
                <Pagination
                    count={Math.ceil(array.length / itemsPerPage)}
                    page={page}
                    onChange={handlePageChange}
                    shape="rounded"
                    />
            </Box>
            </div>
            </>}
        </div>
    )
}