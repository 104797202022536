import React, { useEffect, useState } from "react";
import pic from "./Media/footer.png";
import { useTranslation } from "react-i18next";
import { Box, Button, Container, Link, Stack, Typography, useMediaQuery } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function Footer() {
  const [contact,setContact] = useState({})
  const { t } = useTranslation();
  const pages = [
    t("gallery"),
    t("consultation"),
    t("pricing"),
    t("contact us"),
    t("privacy policy"),
  ];
  const lang = localStorage.getItem("lang");
  const matches = useMediaQuery("(min-width:660px)");

  const navigate = useNavigate();

  const handleNavigation = (p) => {
    let pageInEnglish;
    switch (p) {
      case t("gallery"):
        pageInEnglish = "gallery";
        break;
      case t("consultation"):
        pageInEnglish = "consultation";
        break;
      case t("pricing"):
        pageInEnglish = "pricing";
        break;
        case t('contact us'):
        pageInEnglish = 'contact';
        break;
        case t("privacy policy"):
          pageInEnglish = "privacy policy";
          break;
      default:
        pageInEnglish = p;
        break;
    }
    if (pageInEnglish === "contact") {
      navigate('/contact');
    }
    if (pageInEnglish === "privacy policy") {
      navigate("/privacy");
    } else {
      navigate(`/${pageInEnglish}`);
    }
  };
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}home`, {
      headers: {
        "Accept-Language": lang,
      },
    }).then(res=>{
      setContact(res.data.data.general)
    })
  }, []);
  return (
    <div>
      <footer
        style={{
          backgroundColor: "#C5C0BB",
          marginTop: "10px",
          padding: "40px",
        }}
      >

        <Stack direction='row' sx={{display:'flex',flexWrap:'wrap',justifyContent:'start'}} gap={matches?12:2}>
        <Typography noWrap component="a" href="/" sx={{}}>
          <img src={pic} style={{ height: matches?"100px":'60px',objectFit:matches?'':'contain' }} />
        </Typography>
        <Box
          style={{
            flexGrow: 1,
            display: matches ? "flex" : "",
            gap: "10px",
            justifyContent: matches ? "end" : "center",
            marginTop: matches ? "" : "30px",
          }}
        >
          {pages.map((page) => (
            <Button
              key={page}
              sx={{ color: "white", letterSpacing: lang === "en" ? "" : "0px",fontSize:lang === 'en'?'13px':'20px' }}
              onClick={() => {
                handleNavigation(page);
              }}
            >
              {page}
            </Button>
          ))}
        </Box>
        </Stack>
        <p
          style={{
            color: "white",
            marginTop: "70px",
            fontSize: "15px",
            letterSpacing: lang === "en" ? "" : "0px",
            width:'250px'
          }}
        >
            {contact.slogan}
        </p>

        <Stack
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: matches ? "end" : "start",
            alignItems: matches ? "end" : "center",
            mt: matches ? "-50px" : "30px",
          }}
        >
          <Stack direction="row" gap={2}>
            {contact.instagram &&
            <Link target="_blank" href={contact.instagram}>
              <InstagramIcon
                sx={{ color: "white", cursor: "pointer", fontSize: "30px" }}
              />
            </Link>}

            {contact.linkedin &&
            <Link target="_blank" href={contact.linkedin}>
              <LinkedInIcon
                sx={{ color: "white", cursor: "pointer", fontSize: "30px" }}
              />
            </Link>}

            {contact.facebook &&
            <Link target="_blank" href={contact.facebook}>
              <FacebookIcon
                sx={{ color: "white", cursor: "pointer", fontSize: "30px" }}
              />
            </Link>}

            {contact.twitter &&
            <Link target="_blank" href={contact.twitter}>
              <TwitterIcon
                sx={{ color: "white", cursor: "pointer", fontSize: "30px" }}
              />
            </Link>}
          </Stack>

          <p style={{ color: "white" }}>
            {contact.email}
            <br /> {contact.phone}
          </p>
        </Stack>
      </footer>
    </div>
  );
}
