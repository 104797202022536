import { Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export default function GalleryCard({ data }) {
    const [lang, setLang] = useState(localStorage.getItem('lang'))
    const [hoveredIndex, setHoveredIndex] = useState(-1)

    const handleMouseEnter = (index) => {
        setHoveredIndex(index)
    }

    const handleMouseLeave = () => {
        setHoveredIndex(-1)
    }

    const navigate = useNavigate()

    return (
        <div style={{ marginTop: '10px' }} className='image_container'>
            {data.map((data, index) => (
                <Stack
                    key={index}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer'
                    }}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                    onClick={()=>{navigate('/details',{state:{data:data.id}})}}
                >
                    <div className="p_card">
                        <img
                            className="p_card__background"
                            src={data.image}
                        />
                    </div>
                    <Typography
                        sx={{
                            color: "#3D3B37",
                            letterSpacing: lang === 'en' ? '' : '0px',
                            fontSize: hoveredIndex === index ?'18px':'15px',
                            fontWeight: hoveredIndex === index ? 'bolder' : 'normal',
                            transition: 'font-weight 0.3s ease-in-out',textAlign:'center'
                        }}
                    >
                        {data.title}
                    </Typography>
                </Stack>
            ))}
        </div>
    )
}