import React, { useEffect, useState } from 'react'
import { Container, Typography, useMediaQuery } from '@mui/material'
import axios from 'axios';
import LoadingPage from '../Components/LoadingPage';
import { useTranslation } from "react-i18next";

export default function PrivacyPolicy() {
    const [im,setIm] = useState()
    const [open, setOpen] = useState(true);
    const [title,setTitle] = useState()
    const [privacy,setPrivacy] = useState()
    const matches = useMediaQuery('(min-width:700px)');
    const [lang,setLang] = useState(localStorage.getItem('lang'))
    const { t } = useTranslation();

    useEffect(()=>{
      window.scrollTo(0,0)
      axios.get(`${process.env.REACT_APP_API_URL}privacy`, {
        headers: {
          "Accept-Language": lang,
        },
      }).then(res =>{
       setIm(res.data.data.header_image)
       setTitle(res.data.data.title)
       setPrivacy(res.data.data.privacy)
       console.log(res.data)
       setOpen(false)
      })
  },[])

    return (
        <div>
          {open && <LoadingPage open={open}/>}
          {!open && <>
             <div style={{ position: "relative", display: "flex",flexWrap:'wrap',
      justifyContent: "center", alignItems: "center", height: matches ? "700px" : "300px" }}>
  <img
    src={im}
    style={{
      height: matches ? "700px" : "300px",
      objectFit: "cover",
      width: "100%",
    }}
  />
  <div className="Slider-overLay"></div>
  <p
    className="sections-text"
    style={{ letterSpacing: lang === "en" ? "" : "0px"}}
  >
   {t('PRIVACY POLICY')}
  </p>
</div>

<Container maxWidth='lg'>
<Typography
          sx={{ fontSize: '20px', color: '#888888',mb:'100px',letterSpacing:'0px' }}
          dangerouslySetInnerHTML={{ __html: privacy }}
        ></Typography>
</Container>
            </>}
        </div>
    )
}