import React, { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";
import { Box, Button, Stack, Typography, useMediaQuery } from '@mui/material';
import TextFieldComp from './TextFieldComp';
import axios from 'axios';
import Success from './Success';

export default function ConsDialog({open,setOpen,id}) {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const { t } = useTranslation();
    const [openSuccess,setOpenSuccess] = useState(false)
    const [opening,setOpening] = useState(true)
    const [lang, setLang] = React.useState(localStorage.getItem("lang"));    
    const [privacy,setPrivacy] = useState()

    const handleClose = () => {
        setOpen(false);
        setOpening(true)
      };
      useEffect(()=>{
        window.scrollTo(0,0)
        axios.get(`${process.env.REACT_APP_API_URL}privacy`, {
          headers: {
            "Accept-Language": lang,
          },
        }).then(res =>{
         setPrivacy(res.data.data.privacy)
        })
    },[])     
     const matches = useMediaQuery("(min-width:700px)");
      const handleSend = () => {
        if (firstName && lastName && email && phone && message && subject) {
          const formData = new FormData();
          formData.append("area_id", id);
          formData.append("first_name", firstName);
          formData.append("last_name", lastName);
          formData.append("phone_number", phone);
          formData.append("email", email);
          formData.append("subject", subject);
          formData.append("message", message);
       
          axios
            .post(`${process.env.REACT_APP_API_URL}form`, formData, {
              headers: {
                "Accept-Language": lang,
              },
            })
            .then((res) => {
              setOpenSuccess(true)
              setFirstName("");
              setLastName("");
              setSubject("");
              setEmail("");
              setPhone("");
              setMessage("");
              handleClose()
            });
 }else {
          alert("all fields must be fill");
        }
      };


  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        maxWidth='sm'
        fullWidth='sm'
      >
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            left: 8,
            top: 8,
            color: '#3D3B37'
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{gap:'10px',display:'flex',flexDirection:'column'}}>
        {opening && <div style={{display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'center'}}>
        <Typography sx={{fontWeight:'bold',fontSize:'20px',color: "#91877D"}}>
          {t('Terms & Conditions')}
        </Typography>
        <div style={{overflowY:'scroll',height:'350px',marginTop:'20px'}}>
        <Typography
          sx={{ color: '#888888',mb:'100px',letterSpacing:'0px' }}
          dangerouslySetInnerHTML={{ __html: privacy }}
        ></Typography>
        </div>

        <Button sx={{bgcolor:'transparent',bgcolor:'#91877D',color:'white',mt:'20px',
                    "&:hover": { color: "white", bgcolor: "#91877D" }}}
                    onClick={()=>{setOpening(false)}}>{t('Agree')}</Button>
        </div>
        }

       {!opening && <> <Stack direction={matches ? "row" : "column"} gap={1} sx={{width:'100%',mt:matches?'':'30px'}}>
        <Box sx={{width:matches?'50%':'100%'}}>
        <TextFieldComp
                    label="First name"
                    value={firstName}
                    onChange={setFirstName}
                    width={"100%"}
                    h={"30px"}
                  />
        </Box>
        <Box sx={{width:matches?'50%':'100%'}}>
                  <TextFieldComp
                    label="Last name"
                    value={lastName}
                    onChange={setLastName}
                    width={"100%"}
                    h={"30px"}
                  />
                  </Box>
                </Stack>


                <Stack direction={matches ? "row" : "column"} gap={1} sx={{width:'100%'}}>
                  <Box sx={{width:matches?'50%':'100%'}}>
                  <TextFieldComp
                    label="Email"
                    value={email}
                    onChange={setEmail}
                    width={"100%"}
                    h={"30px"}
                    type={'email'}
                  />
                  </Box>

                  <Box sx={{width:matches?'50%':'100%'}}>
                  <TextFieldComp
                    label="Phone number"
                    value={phone}
                    onChange={setPhone}
                    width={"100%"}
                    h={"30px"}
                    type={"number"}
                  />
                  </Box>
                </Stack>
                <TextFieldComp
                  label="Subject"
                  value={subject}
                  onChange={setSubject}
                  width={"100%"}
                  h={"30px"}
                />
                <TextFieldComp
                  label="Message"
                  value={message}
                  onChange={setMessage}
                  width={"100%"}
                  h={"70px"}
                />

                <Button
                  sx={{
                    letterSpacing: lang === "en" ? "" : "0px",
                    color: "white",
                    bgcolor: "#91877D",
                    mt: "30px",
                    width: "100%",
                    "&:hover": { color: "white", bgcolor: "#91877D" },
                  }}
                  onClick={handleSend}
                >
                  {t("SUBMIT")}
                </Button></>}
        </DialogContent>
      </Dialog>
      <Success open={openSuccess} setOpen={setOpenSuccess}/>
      </>
  )
}
