import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import i18next from "i18next";
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

function Navbar({color, logo, logoOpacity, menuColor}) {
  const { t, i18n } = useTranslation();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [lan, setLan] = React.useState('') 
  const pages = [t('gallery'), t('consultation'), t('pricing'), t('contact us')];
  const lang = localStorage.getItem('lang');
  document.body.dir = i18n.dir();
  const matches = useMediaQuery("(min-width:700px)");

  React.useEffect(()=>{
    if (lang === 'ar'){
      setLan(false)
    }
    else{setLan(true)}
  },[])

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleEn = () => {
    i18next.changeLanguage('en');
    localStorage.setItem('lang','en')
    setLan(true)
    window.location.reload(true)
  };

  const handleAr = () => {
    i18next.changeLanguage('ar');
    localStorage.setItem('lang','ar')
    setLan(false)
    window.location.reload(true)
  };

  const navigate = useNavigate()

  const handleNavigation = (p) => {
    let pageInEnglish;
    switch (p) {
      case t('gallery'):
        pageInEnglish = 'gallery';
        break;
      case t('consultation'):
        pageInEnglish = 'consultation';
        break;
      case t('pricing'):
        pageInEnglish = 'pricing';
        break;
      case t('contact us'):
        pageInEnglish = 'contact us';
        break;
      default:
        pageInEnglish = p;
        break;
    }
    if (pageInEnglish === 'contact us') {
      navigate('/contact');
    } 
     else {
      navigate(`/${pageInEnglish}`);
    }
  };
  return (
    <AppBar  sx={{p:matches?'40px':'0px',bgcolor:'transparent',boxShadow:'none',position:'absolute'
    ,paddingTop:matches?'':'40px'}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{justifyContent:'start'}}
          >
            <img src={logo} style={{height:matches?'60px':'50px',
            objectFit:matches?'':'contain',
            opacity:logoOpacity,width:matches?'':'100%',paddingRight:!matches && lang ==='en' ?'20px':!matches && lang ==='ar' ? '5px':''
            }}/>
          </Typography>


          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } ,justifyContent:'end'}}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              sx={{color:menuColor}}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' }
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Button textAlign="center"  sx={{color: 'black',letterSpacing:lang === 'en' ?'' : '0px'}}
                  onClick={()=>{handleNavigation(page)}}
                  >{page}
                  </Button>
                </MenuItem>
              ))}
              {lan &&
            <Button  sx={{ my: 1, color: 'black', display: 'block' ,letterSpacing:'0px',fontFamily:'"Almarai", sans-serif',
          ml:'5px'}}
            onClick={handleAr}>عربي</Button>
            }
            {!lan && 
            <Button  sx={{ my: 1, color: 'black', display: 'block'
            ,fontFamily:'"Libre Bodoni", serif',mr:'15px' }}
            onClick={handleEn}>English
            </Button>}
            </Menu>
          </Box>
         



          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' },gap:'15px' }}>
           {lan &&
            <Button  sx={{ my: 2, color: color, display: 'block' ,letterSpacing:'0px',fontFamily:'"Almarai", sans-serif'}}
            onClick={handleAr}>عربي</Button>
            }
            {!lan && 
            <Button  sx={{ my: 2, color: color, display: 'block',fontFamily:'"Libre Bodoni", serif' }}
            onClick={handleEn}>English
            </Button>}
            {pages.map((page) => (
              <Button
                key={page}
                sx={{ my: 2, color: color, display: 'block',letterSpacing:lang === 'en' ?'' : '0px'}}
                onClick={()=>{handleNavigation(page)}}>
                {page}
              </Button>
            ))}
          </Box>

          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Navbar;