import { HelmetProvider } from "react-helmet-async";
import { HashRouter, Route, Routes, useLocation } from "react-router-dom";
import MainPage from "./Pages/MainPage";
import Navbar from "./Navbar";
import { createTheme, ThemeProvider } from "@mui/material";
import { useEffect } from "react";
import Footer from "./Footer";
import Contact from "./Pages/Contact";
import Gallery from "./Pages/Gallery";
import Details from "./Pages/Details";
import pic from "./Media/Logo.png";
import picture from "./Media/coloredlogo.png";
import Consultation from "./Pages/Consultation";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Pricing from "./Pages/Pricing";
import InnerConsultation from "./Pages/InnerConsultation";

function App() {
  const lang = localStorage.getItem("lang");
  const theme = createTheme({
    typography: {
      fontFamily: lang === "en" ? '"Libre Bodoni", serif' : '"Almarai", sans-serif',
      letterSpacing: lang === "en " ? "3px" : "0px",
    },
    button: {
      fontFamily: lang === "en" ? '"Libre Bodoni", serif' : '"Almarai", sans-serif',
      letterSpacing: lang === "en " ? "3px" : "0px",
    },
  });

  const color = {
    details: { color: "#3D3B37" },
    other: { color: "white" },
  };

  const logo = {
    details: picture,
    other: pic,
  };

  const logoOpacity = {
    details: 1,
    other: 0.7,
  };

  const menuColor = {
    details: '#3D3B37',
    other: 'white',
  };
  useEffect(() => {
    if (!lang) {
      localStorage.setItem("lang", "en");
    }
  }, []);

  return (
    <div style={{ padding: "10px" }}>
      <HelmetProvider>
        <HashRouter>
          <ThemeProvider theme={theme}>
            <AppWithNavbar color={color} logo={logo} logoOpacity={logoOpacity} menuColor={menuColor} />
            <Routes>
              <Route path="/" element={<MainPage />} />
              <Route path="/gallery" element={<Gallery />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/details" element={<Details />} />
              <Route path="/consultation" element={<Consultation />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route
                path="/innerconsultation"
                element={<InnerConsultation />}
              />
            </Routes>
            <Footer />
          </ThemeProvider>
        </HashRouter>
      </HelmetProvider>
    </div>
  );
}

function AppWithNavbar({ color, logo, logoOpacity, menuColor }) {
  const location = useLocation();

  return (
    <>
      {location.pathname === "/details" ? (
        <Navbar color={color.details} logo={logo.details} logoOpacity={logoOpacity.details} menuColor={menuColor.details}/>
      ) : location.pathname === "/innerconsultation" ? (
        <Navbar color={color.details} logo={logo.details} logoOpacity={logoOpacity.details} menuColor={menuColor.details}/>
      ) : location.pathname === "/gallery" ? (
        <Navbar color={color.details} logo={logo.details} logoOpacity={logoOpacity.details} menuColor={menuColor.details}/>
      ) : location.pathname === "/consultation" ? (
        <Navbar color={color.details} logo={logo.details} logoOpacity={logoOpacity.details} menuColor={menuColor.details}/>
      ) : location.pathname === "/pricing" ? (
        <Navbar color={color.details} logo={logo.details} logoOpacity={logoOpacity.details} menuColor={menuColor.details}/>
      ) : location.pathname === "/contact" ? (
        <Navbar color={color.details} logo={logo.details} logoOpacity={logoOpacity.details} menuColor={menuColor.details}/>
      ) : (
        <Navbar color={color.other} logo={logo.other} logoOpacity={logoOpacity.other} menuColor={menuColor.other}/>
      )}
    </>
  );
}

export default App;
