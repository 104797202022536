import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, EffectCoverflow, EffectCreative } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";
import { Button, Container, Typography, useMediaQuery } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import axios from "axios";
import LoadingPage from "../Components/LoadingPage";
import { useTranslation } from "react-i18next";
import ConsDialog from "../Components/ConsDialog";

export default function InnerConsultation() {
  const location = useLocation();
  const { state } = location;
  const { id } = state;
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:1024px)");
  const [lang, setLang] = useState(localStorage.getItem("lang"));
  const [open, setOpen] = useState(true);
  const [areas, setAreas] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [a_id, setAId] = useState();
  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => {
    localStorage.setItem("area_id", id);
    window.scrollTo(0, 0);
    axios
      .get(`${process.env.REACT_APP_API_URL}areas/${id}`, {
        headers: {
          "Accept-Language": lang,
        },
      })
      .then((res) => {
        setAreas(res.data.data[1]);
        setOpen(false);
        setActiveIndex(Math.floor(res.data.data[1].length / 2))
      });
  }, []);
  return (
    <div style={{ marginTop: "150px", marginBottom: "100px" }}>
      {open && <LoadingPage open={open} />}

      {!open && (
        <>
          <Container maxWidth="lg" sx={{ 
                justifyContent: "center",
                alignItems:'center'}}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                alignItems:'center'
              }}
            >
              <Typography
                sx={{
                  letterSpacing: lang === "en" ? "" : "0px",
                  color: "#89847E",
                  fontSize: "20px",
                  mb: "50px",
                }}
              >
                {t("AREAS OF")} <br />
                {t("CONSULTATION")}
              </Typography>
            </div>
              <Swiper
              modules={[Navigation, EffectCoverflow, EffectCreative]}
              onActiveIndexChange={(swiper) => setActiveIndex(swiper.activeIndex)}
              spaceBetween={15}
              slidesPerView={matches ? 5 : 1}
              centeredSlides={true}
              rewind
              loopAddBlankSlides
              loopedSlides={areas.length}
              initialSlide={Math.floor(areas.length / 2)}
              effect={matches ? "coverflow" : "creative"}
              coverflowEffect={{
                rotate: 0,
                stretch: matches ? 12 : 0,
                depth: matches ? 7 : 0,
                modifier: matches ? 35 : 0 ,
                slideShadows: false,
              }}
              navigation={{
                prevEl: ".custom-swiper-prev",
                nextEl: ".custom-swiper-next",
              }}
            >
              {areas &&
                areas.map((a,index) => (
                  <SwiperSlide
                    style={{
                      position: "relative",
                      display: matches ? "flex" : "flex",
                      justifyContent: "center",
                      alignItems:'center',
                      flexWrap:'wrap',
                      border:'2px solid white', 
                    }}
                  >
                    <div
                      style={{
                        height: "400px",
                        width: "324px",
                        backgroundColor:'#C5C0BB',
                        position:'relative',
                        display:'flex',
                        flexWrap:'wrap',
                        justifyContent:'center',
                        alignItems:'center',
                      }}
                    >
                      <img src={a.image} style={{height:'150px',width:'150px',
                                          opacity: index === activeIndex ? 1 : 0.3,
                                        }}/>
                    
                    <Typography
                      sx={{
                        position: "absolute",
                        top: "20px",
                        color: "white",
                        textAlign: "center",
                        zIndex: 10,
                        letterSpacing:'0px'
                      }}
                    >
                      <Container maxWidth='xs'>
                     {a.title}
                     </Container>
                    </Typography>
                    <Button
                      sx={{
                        position: "absolute",
                        bottom: "5%",
                        color: "#3D3B37",
                        backgroundColor: "white",
                        p: "10px",
                        width: "200px",
                        fontWeight: "bold",
                        "&:hover": {
                          color: "white",
                          backgroundColor: "#3D3B37",
                        },
                      }}
                      onClick={() => {
                        setOpenDialog(true);
                        setAId(a.id);
                      }}
                    >
                      {t("Book now")}
                    </Button>
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          </Container>

          <>
            <div className="custom-swiper-prev">
              <ArrowBackIosNewIcon
                style={{
                  color: "#89847E",
                  fontSize: matches ? "40px" : "30px",
                  position: "absolute",
                  top: "450px",
                  left: matches ? "10px" : "2px",
                  zIndex: "1",
                  cursor: "pointer",
                }}
              />
            </div>
            <div className="custom-swiper-next">
              <ArrowForwardIosIcon
                style={{
                  color: "#89847E",
                  fontSize: matches ? "40px" : "30px",
                  position: "absolute",
                  top: "450px",
                  right: matches ? "10px" : "2px",
                  zIndex: "1",
                  cursor: "pointer",
                }}
              />
            </div>
          </>
        </>
      )}

      <ConsDialog open={openDialog} setOpen={setOpenDialog} id={a_id} />
    </div>
  );
}
