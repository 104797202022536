import React, { useState } from "react";
import { Button, Container, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function ConsCard({ image, title, id }) {
  const matches = useMediaQuery("(min-width:700px)");
  const [lang, setLang] = useState(localStorage.getItem("lang"));
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div>
      <div
        style={{
          position: "relative",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          height: matches ? "50vw" : "300px",
          marginBottom: "50px",
        }}
      >
        <img
          src={image}
          style={{
            height: matches ? "100%" : "300px",
            objectFit: "cover",
            width: "100%",
          }}
        />
        <div className="consultation-overLay"></div>
        <p
          className="sections-text"
          style={{ letterSpacing: lang === "en" ? "" : "0px" }}
        >
          <Container
            sx={{
              letterSpacing: lang === "en" ? "" : "0px",
              width:matches?'xs':'200px'
            }}
          >
            {t(title)}
          </Container>
        </p>

        <Button
          sx={{
            position: "absolute",
            bottom: matches ? "30%" : "10%",
            color: "#3D3B37",
            backgroundColor: "white",
            p: "10px",
            width: "200px",
            fontWeight: "bold",
            "&:hover": { color: "white", backgroundColor: "#3D3B37" },
            letterSpacing: lang === "en" ? "" : "0px",
          }}
          onClick={() => {
            navigate("/innerconsultation", { state: { id: id } });
          }}
        >
          {t("Book now")}
        </Button>
      </div>
    </div>
  );
}
