import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Container,
  Stack,
  Typography,
  useMediaQuery,
  Grid,
} from "@mui/material";
import TextFieldComp from "../Components/TextFieldComp";
import axios from "axios";
import LoadingPage from "../Components/LoadingPage";
import Success from "../Components/Success";

export default function Contact() {
  const matches = useMediaQuery("(min-width:700px)");
  const [lang, setLang] = useState(localStorage.getItem("lang"));
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [mainImage, setMainImage] = useState();
  const [desc, setDesc] = useState();
  const [Image, setImage] = useState();
  const [open, setOpen] = useState(true);
  const [openSuccess,setOpenSuccess] = useState(false)
  const windowHeight = React.useRef(window.innerHeight);

  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${process.env.REACT_APP_API_URL}contact`, {
        headers: {
          "Accept-Language": lang,
        },
      })
      .then((res) => {
        setMainImage(res.data.data.header_image);
        setImage(res.data.data.image);
        setDesc(res.data.data.description);
        setOpen(false);
      });
  }, []);

  const handleSend = () => {
    if (firstName && lastName && email && phone && message && subject) {
      const formData = new FormData();
      formData.append("first_name", firstName);
      formData.append("last_name", lastName);
      formData.append("phone_number", phone);
      formData.append("email", email);
      formData.append("subject", subject);
      formData.append("message", message);
      axios
        .post(`${process.env.REACT_APP_API_URL}form`, formData, {
          headers: {
            "Accept-Language": lang,
          },
        })
        .then((res) => {
          setOpenSuccess(true)
          setFirstName("");
          setLastName("");
          setSubject("");
          setEmail("");
          setPhone("");
          setMessage("");
        });
    } else {
      alert("all fields must be fill");
    }
  };

  return (
    <div style={{ marginBottom: "50px" }}>
      {open && <LoadingPage open={open} />}

      {!open && (
        <>
          {/* <div
            style={{
              position: "relative",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              height: matches ? windowHeight.current  : "300px",
            }}
          >
            <img
              src={mainImage}
              style={{
                height: matches ? windowHeight.current  : "300px",
                objectFit: "cover",
                width: "100%",
              }}
            />
            <div className="Slider-overLay"></div>
            <p
              className="sections-text"
              style={{ letterSpacing: lang === "en" ? "" : "0px" }}
            >
              {t("CONTACT US")}
            </p>
          </div> */}
          <div style={{marginTop:'150px'}}>
          <Stack
            direction="row"
            sx={{ display: "flex", flexWrap: "wrap", mt: "10px" }}
            gap={4}
          >
              <img
                src={Image}
                style={{
                  width: matches ? "40%" : "100%",
                  height: matches ? "570px" : "300px",
                  objectFit:'cover'
                }}
              />
            
            <Stack sx={{ width: matches? "54%" :'100%' , p : matches ? ' ' : '20px'  }}>
              <Typography
                sx={{
                  color: "#3D3B37",
                  fontSize: "20px",
                  fontWeight: "bold",
                  mt: matches ? "" : "20px",
                  letterSpacing: lang === "en" ? "" : "0px",
                }}
              >
                {t("Contact Us")}
              </Typography>

              <Typography
                sx={{
                  color: "#3D3B37",
                  mt: matches ? "30px" : "20px",
                  letterSpacing: "0px",
                }}
              >
                {desc}
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextFieldComp
                    label="First name"
                    value={firstName}
                    onChange={setFirstName}
                    width={"100%"}
                    h={"30px"}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextFieldComp
                    label="Last name"
                    value={lastName}
                    onChange={setLastName}
                    width={"100%"}
                    h={"30px"}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextFieldComp
                    label="Email"
                    value={email}
                    onChange={setEmail}
                    width={"100%"}
                    h={"30px"}
                    type={"email"}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextFieldComp
                    label="Phone number"
                    value={phone}
                    onChange={setPhone}
                    width={"100%"}
                    h={"30px"}
                    type={"number"}
                  />
                </Grid>
              </Grid>
              <TextFieldComp
                label="Subject"
                value={subject}
                onChange={setSubject}
                width={"100%"}
                h={"30px"}
              />
              <TextFieldComp
                label="Message"
                value={message}
                onChange={setMessage}
                width={"100%"}
                h={"70px"}
              />
              <Button
                sx={{
                  letterSpacing: lang === "en" ? "" : "0px",
                  color: "white",
                  bgcolor: "#91877D",
                  mt: "30px",
                  width: "100%",
                  "&:hover": { color: "white", bgcolor: "#91877D" },
                }}
                onClick={handleSend}
              >
                {t("SUBMIT")}
              </Button>
            </Stack>
          </Stack>
          </div>
        </>
      )}
      <Success open={openSuccess} setOpen={setOpenSuccess}/>
    </div>
  );
}
