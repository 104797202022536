import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Container,
  Stack,
  Typography,
  useMediaQuery,
  Grid,
} from "@mui/material";
import TextFieldComp from "../Components/TextFieldComp";
import axios from "axios";
import LoadingPage from "../Components/LoadingPage";
import Success from "../Components/Success";

export default function Pricing() {
  const matches = useMediaQuery("(min-width:700px)");
  const [lang, setLang] = useState(localStorage.getItem("lang"));
  const { t } = useTranslation();
  const [clientName, setClientName] = useState("");
  const [projectName, setProjectName] = useState("");
  const [area, setArea] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [projectAddress, setProjectAddress] = useState("");
  const [message, setMessage] = useState("");
  const [mainImage, setMainImage] = useState();
  const [Image, setImage] = useState();
  const [open, setOpen] = useState(true);
  const [openSuccess,setOpenSuccess] = useState(false)
  const windowHeight = React.useRef(window.innerHeight);
  const [desc, setDesc] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${process.env.REACT_APP_API_URL}pricing`, {
        headers: {
          "Accept-Language": lang,
        },
      })
      .then((res) => {
        setMainImage(res.data.data.header_image);
        setImage(res.data.data.main_image)
        setDesc(res.data.data.description);
        setOpen(false);
      });
  }, []);

  const handleSend = () => {
    if (
      clientName &&
      projectName &&
      area &&
      projectAddress &&
      message &&
      email &&
      phone
    ) {
      const formData = new FormData();
      formData.append("client_name", clientName);
      formData.append("project_type", projectName);
      formData.append("area", area);
      formData.append("address", projectAddress);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("message", message);
      axios
        .post(`${process.env.REACT_APP_API_URL}pricing`, formData, {
          headers: {
            "Accept-Language": lang,
          },
        })
        .then((res) => {
          setOpenSuccess(true)
          setClientName("");
          setProjectName("");
          setArea("");
          setPhone("");
          setEmail("");
          setProjectAddress("");
          setMessage("");
        });
    } else {
      alert("all fields must be fill");
    }
  };

  return (
    <div style={{ marginBottom: "50px" }}>
      {open && <LoadingPage open={open} />}

      {!open && (
        <>
        {/* <div
            style={{
              position: "relative",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              height: matches ? windowHeight.current  : "300px",
            }}
          >
            <img
              src={mainImage}
              style={{
                height: matches ? windowHeight.current  : "300px",
                objectFit: "cover",
                width: "100%",
              }}
            />
            <div className="Slider-overLay"></div>
            <p
              className="sections-text"
              style={{ letterSpacing: lang === "en" ? "" : "0px" }}
            >
              {t("pricing")}
            </p>
          </div> */}
          <div style={{marginTop:'150px'}}>
          <Stack
            direction="row"
            sx={{ display: "flex", flexWrap: "wrap", mt: "10px" }}
            gap={4}
          >
              <img
                src={Image}
                style={{
                  width: matches ? "40%" : "100%",
                  height: matches ? "460px" : "300px",
                  objectFit:'cover'
                }}
              />
            <Stack sx={{ width: matches? "54%" :'100%' , p : matches ? ' ' : '20px' }}>
              <Typography
                sx={{
                  color: "#3D3B37",
                  fontSize: "20px",
                  fontWeight: "bold",
                  mt: matches ? "" : "20px",
                  letterSpacing: lang === "en" ? "" : "0px",
                }}
              >
                {t("Pricing")}
              </Typography>
              <Typography
                sx={{
                  color: "#3D3B37",
                  mt: matches ? "30px" : "20px",
                  letterSpacing: "0px",
                }}
              >
                {desc}
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextFieldComp
                    label="Client name"
                    value={clientName}
                    onChange={setClientName}
                    width={"100%"}
                    h={"30px"}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextFieldComp
                    label="Project type"
                    value={projectName}
                    onChange={setProjectName}
                    width={"100%"}
                    h={"30px"}
                    data={[t("Villa"), t("Apartment"),t("Hotel"),t("Farm house") ,t("Outdoor area") ,t("Commercia") ,t("Corporate")]}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextFieldComp
                    label="Area"
                    value={area}
                    onChange={setArea}
                    width={"100%"}
                    h={"30px"}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextFieldComp
                    label="Project address"
                    value={projectAddress}
                    onChange={setProjectAddress}
                    width={"100%"}
                    h={"30px"}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextFieldComp
                    label="Contact phone number"
                    value={phone}
                    onChange={setPhone}
                    width={"100%"}
                    h={"30px"}
                    type={'number'}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextFieldComp
                    label="Email address"
                    value={email}
                    onChange={setEmail}
                    width={"100%"}
                    h={"30px"}
                    type={'email'}
                  />
                </Grid>
              </Grid>

              <TextFieldComp
                label="Please write a brief description of the project and the service you want us to provide"
                value={message}
                onChange={setMessage}
                width={"100%"}
                h={"70px"}
              />

              <Button
                sx={{
                  letterSpacing: lang === "en" ? "" : "0px",
                  color: "white",
                  bgcolor: "#91877D",
                  mt: "30px",
                  width: "100%",
                  "&:hover": { color: "white", bgcolor: "#91877D" },
                }}
                onClick={handleSend}
              >
                {t("SUBMIT")}
              </Button>
            </Stack>
          </Stack>
          </div>
        </>
      )}
            <Success open={openSuccess} setOpen={setOpenSuccess}/>
    </div>
  );
}
