import { TextField, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { useState } from "react";

export default function TextFieldComp({ label, value, onChange, width, h, type, data }) {
  const { t } = useTranslation();
  const [lang, setLang] = useState(localStorage.getItem("lang"));
  const [emailError, setEmailError] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChange = (newValue) => {
    setEmailError(!validateEmail(newValue));
    onChange(newValue);
  };
  const customTheme = (outerTheme) =>
    createTheme({
      palette: {
        mode: outerTheme.palette.mode,
      },
      components: {
        MuiInputLabel: {
          styleOverrides: {
            root: {
              color: "rgba(196, 196, 196, 0.582)",
              height: h,
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
                height: h,
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
                height: h,
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
                height: h,
              },
            },
          },
        },
        MuiFilledInput: {
          styleOverrides: {
            root: {
              "&::before, &::after": {
                borderBottomColor: "transparent",
                height: h,
              },
              "&:hover:not(.Mui-disabled):before": {
                borderBottomColor: "transparent",
                height: h,
              },
              "&.Mui-focused:after": {
                borderBottomColor: "transparent",
                height: h,
              },
            },
          },
        },
        MuiInput: {
          styleOverrides: {
            root: {
              "&::before": {
                borderBottomColor: "rgba(196, 196, 196, 0.582)",
                height: h,
              },
              "&:hover:not(.Mui-disabled):before": {
                borderBottomColor: "rgba(196, 196, 196, 0.582)",
                height: h,
              },
              "&.Mui-focused:after": {
                borderBottomColor: "rgba(196, 196, 196, 0.582)",
                height: h,
              },
            },
          },
        },
      },
    });

  const outerTheme = useTheme();

  return (
    <div style={{ marginTop: "30px" }}>
      <label
        style={{
          color: "#3D3B37",
          letterSpacing: lang === "en" ? "0px" : "0px",
        }}
      >
        {t(label)}
      </label>
      <br />
      <ThemeProvider theme={customTheme(outerTheme)}>
      <TextField
          type={type}
          select={data ? true : false}
          value={value}
          onChange={(e) => handleChange(e.target.value)}
          error={type==='email' && emailError}
          helperText={type==='email' && emailError ? "Please enter a valid email address" : ""}
          sx={{
            backgroundColor: "#F0F0F0",
            width: width,
            letterSpacing: "0px",
            height: h,
            ".MuiInputBase-input": { height: h },
            ".MuiInputBase-root ": { height: h },
            ".MuiSvgIcon-root" :{right : lang === 'en' ? '7px' : '90%'}
          }}
        >
          {data && data.map((s) => (
            <MenuItem value={s} >{s}</MenuItem>
          ))}
        </TextField>
      </ThemeProvider>
    </div>
  );
}
