import React, { useEffect, useState } from "react";
import Slider from "../Components/Slider";
import Info from "../Components/Info";
import Sections from "../Components/Sections";
import axios from "axios";
import LoadingPage from "../Components/LoadingPage";

export default function MainPage() {
  const lang = localStorage.getItem("lang");
  const [open, setOpen] = useState(true);
  const [sliders,setSliders] = useState([])
  const [sec,setSec] = useState([])
  const [story,setStory] = useState({})
  useEffect(() => {
    window.scrollTo(0, 0);
    axios.get(`${process.env.REACT_APP_API_URL}home`, {
      headers: {
        "Accept-Language": lang,
      },
    }).then(res=>{
      setSliders(res.data.data.sliders)
      setSec(res.data.data.sections)
      setStory(res.data.data.general)
      setOpen(false)
    })
  }, []);
  return (
    <div>
      {open && <LoadingPage open={open}/>}
      {!open && (
        <>
          <Slider sliders={sliders} />
          <Info story={story} />
          <Sections sec={sec} />
        </>
      )}
    </div>
  );
}
