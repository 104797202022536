import { Button } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export default function Sections({ sec }) {
  const [lang, setLang] = useState(localStorage.getItem("lang"));
  const { t } = useTranslation();
  
  return (
    <div style={{ marginTop: "10px" }}>
      <div className="cards_container">
        {sec.map((s) => (
          <a  href={s.url}>
          <div style={{ position: "relative", cursor:'pointer' }}>
            <img src={s.main_image} className="im" />
            <div className="overlay">
              <Button
                sx={{
                  letterSpacing: lang === "en" ? "" : "0px",
                  textTransform: "capitalize",
                  color: "white",
                  fontSize: "30px",
                  textAlign: "center",
                  "&:hover": {
                    transition: "transform 0.2s ease-in-out",
                    transform: "translateY(-20px)",
                  },
                }}
              >
                {t(s.key)}
              </Button>
            </div>
          </div>
          </a>))}
      </div>
    </div>
  );
}
