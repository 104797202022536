import React, { useEffect, useState } from "react";
import { Container, Stack, Typography, useMediaQuery } from "@mui/material";
import { useLocation } from "react-router-dom";
import axios from "axios";

export default function Details() {
  const location = useLocation();
  const { state } = location;
  const { data } = state;

  const [mainImage, setMainImage] = useState();
  const [title, setTitle] = useState();
  const [desc, setDesc] = useState();
  const [array, setArray] = useState([]);
  const matches = useMediaQuery("(min-width:600px)");
  const [lang, setLang] = useState(localStorage.getItem("lang"));
  const [mainImageIndex, setMainImageIndex] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${process.env.REACT_APP_API_URL}gallery-details/${data}`, {
        headers: {
          "Accept-Language": lang,
        },
      })
      .then((res) => {
        setMainImage(res.data.data.image);
        setTitle(res.data.data.title);
        setDesc(res.data.data.description);
        setArray(res.data.data.media);
      });
  }, [data, lang]);

  const limitDescription = (desc) => {
    if (!desc) {
      return ["", ""];
    }
  
    const words = desc.split(" ");
    const maxWords = 100;
  
    if (words.length <= maxWords) {
      return [words.join(" "), ""];
    }
  
    const part1 = words.slice(0, maxWords).join(" ");
    const part2 = words.slice(maxWords).join(" ");
  
    return [part1, part2];
  };
  
  const [part1, part2] = limitDescription(desc);

  const handleImageClick = (clickedImage, clickedIndex) => {
    const newArray = [...array];
    newArray[clickedIndex] = mainImage;
    setMainImage(clickedImage);
    setMainImageIndex(clickedIndex);
    setArray(newArray);
  };

  const h = "500px";
  return (
    <div style={{ marginTop: "150px", marginBottom: "100px" }}>
      <Container maxWidth="lg">
        <Stack
          direction="row"
          sx={{ display: "flex", flexWrap: "wrap", alignItems: "start" }}
          gap={1}
        >
          <img
            src={mainImage}
            style={{
              width: matches ? "500px" : "100%",
              height: matches ? "500px" : "400px",
            }}
          />
          <Stack
            direction={matches ? "column" : "row"}
            style={{
              height: matches ? "500px" : "",
              overflowY: matches ? "auto" : "hidden",
              overflowX: matches ? "hidden" : "auto",
            }}
            gap={1}
            sx={{
              "&::-webkit-scrollbar": {
                width: "6px",
                backgroundColor: "#f1f1f1d3",
                borderRadius: "20px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#C5C0BB",
                borderRadius: "20px",
              },
            }}
          >
            {array.map((item, index) => (
              <img
                key={index}
                src={item}
                style={{
                  width: "170px",
                  height: "170px",
                  marginRight: matches && lang === "en" ? "6px" : "",
                  marginBottom: matches ? "" : "6px",
                  marginLeft: matches && lang === "ar" ? "6px" : "",
                  cursor: "pointer",
                }}
                onClick={() => handleImageClick(item, index)}
              />
            ))}
          </Stack>
          <Stack>
            <Container
              maxWidth="xs"
            >
              <Typography
                sx={{
                  color: "#3D3B37",
                  fontSize: "20px",
                  fontWeight: "bold",
                  mt: matches ? "" : "20px",
                  letterSpacing: lang === "en" ? "" : "0px",
                }}
              >
                {title}
              </Typography>
              <Typography
                sx={{
                  color: "#3D3B37",
                  mt: "10px",
                  letterSpacing: lang === "en" ? "" : "0px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                dangerouslySetInnerHTML={{__html: part1}}
              />
            </Container>
          </Stack>
        </Stack>

        <Container>
        {part2 && (
  <Typography
  sx={{
    color: "#3D3B37",
    mt: "10px",
    letterSpacing: lang === "en" ? "" : "0px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }}
    dangerouslySetInnerHTML={{ __html: part2 }}
  />
)}
            </Container>
      </Container>
    </div>
  );
}
