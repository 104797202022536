import React, { useState } from "react";
import bg from "../Media/Background.png";
import pic from "../Media/info.png";
import {
  Box,
  Button,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export default function Info({ story }) {
  const matches = useMediaQuery("(min-width:1024px)");
  const [lang, setLang] = useState(localStorage.getItem("lang"));
  const [showFullDescription, setShowFullDescription] = useState(false);
  const { t } = useTranslation();
  const matche = useMediaQuery("(min-width:500px)");

  const handleReadMore = () => {
    setShowFullDescription(true);
  };
  const handleReadLess = () => {
    setShowFullDescription(false);
    const sectionElement = document.getElementById('info');
          sectionElement.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <div
      style={{
        position: "relative",
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        marginTop: "10px",
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        paddingBottom: matches ? "50px" : "50px",
      }}
      id="info"
    >
      <Stack sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
        <img
          src={pic}
          style={{ height: matches ? "300px" : "200px", marginTop: "-25px" }}
        />
        <Typography
          sx={{
            mt: "30px",
            fontSize: "30px",
            color: "white",
            letterSpacing: lang === "en" ? "" : "0px",
            textAlign: "center"
          }}
        >
          {story.story_title}
        </Typography>

        <Box
          sx={{
            bgcolor: "#E5E3DF",
            width: "70%" ,
            mt: "30px",
            p: matches?"30px":'10px'
          }}
        >
          <Container maxWidth="lg">
          <Typography
  sx={{ color: "black", textAlign: "center", letterSpacing: "0px", fontSize:'15px' }}
>
  {showFullDescription && !matche 
    ?<> <div dangerouslySetInnerHTML={{ __html: story.story_description }} />
    <Button
    onClick={handleReadLess}
    sx={{
      color: "#3D3B37",
      letterSpacing: "0px",
      fontWeight:'bolder', 
      textDecoration: 'underline',
      '&:hover': {
        textDecoration: 'underline',
      },
    }}
  >
    {t('Read Less')}
  </Button>
  </>: <div dangerouslySetInnerHTML={{ __html: story.story_description.substring(0, 240) }} />}
  {!showFullDescription && !matche && (
    <Button
      onClick={handleReadMore}
      sx={{
        color: "#3D3B37",
        letterSpacing: "0px",
        fontWeight:'bolder', 
        textDecoration: 'underline',
        '&:hover': {
          textDecoration: 'underline',
        },
      }}
    >
      {t('Read More')}
    </Button>
  )}
{matche &&  <div dangerouslySetInnerHTML={{ __html: story.story_description }} />
}</Typography>
          </Container>
        </Box>
      </Stack>
    </div>
  );
}