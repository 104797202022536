import { Button, Container, useMediaQuery } from '@mui/material';
import React from 'react';
import '../App.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay,Navigation } from 'swiper/modules';
import 'swiper/css/navigation';


export default function Slider({sliders}) {
const matches = useMediaQuery('(min-width:500px)');
const lang = localStorage.getItem("lang");
const windowHeight = React.useRef(window.innerHeight);

const swiperStyles = `
@media (max-width: 500px) {
  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }
}

.swiper-button-next{
    color: white;
    right:80px
}
.swiper-button-prev {
  color: white;
  left:80px
}
`;

return (
    <div >
<style>{swiperStyles}</style>
      <Swiper modules={[Autoplay,Navigation]} 
       autoplay={{
        delay: 8000,
        disableOnInteraction: false,
      }}
      slidesPerView={1} 
      navigation
      style={{ width: '100%' }}
      >
        {sliders.map((s)=>
          <SwiperSlide style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center',
           alignItems: 'center', position: 'relative' }}>
            <img src={s.image} style={{height:matches?windowHeight.current:'500px',width:'100%',objectFit:matches?'cover':'cover'}}/>
            <div className='Slider-overLay'></div>
  <Container maxWidth='sm'    
   className="sections-text">
  <p 
    style={{ letterSpacing: lang === "en" ? "" : "0px",color:'white'}}
  >{s.title}
  </p>
    <Button sx={{
                letterSpacing: lang === "en" ? "" : "0px",
                color: "white",
                bgcolor: "#91877D",p:'10px',
                "&:hover": { color: "white", bgcolor: "#91877D" },textAlign:'center'
              }} href={s.button_action} target="_blank">{s.button_text}</Button>
  </Container>
</SwiperSlide>)}

      </Swiper>
    </div>
  );
}